<template>
  <div>
    <CoolLightBox
      :items="images.map((i) => getLightBoxImage(i))"
      :index="index"
      @close="index = null"
    >
    </CoolLightBox>

    <v-carousel
      cycle
      height="200"
      hide-delimiter-background
      hide-delimiters
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(slide, i) in images" :key="i">
        <v-sheet height="100%" color="transparent">
          <v-row
            class="fill-height row-pointer"
            align="center"
            justify="center"
            @click="index = i"
          >
            <div class="device-wrapper">
              <div
                class="device"
                data-device="Macbook"
                data-orientation="portrait"
                data-color="white"
              >
                <div class="screen">
                  <v-img
                    :src="`/static/image/${slide.src}`"
                    class="rounded-lg"
                    height="100%"
                    width="100%"
                  ></v-img>
                </div>
              </div>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>


<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "html5-device-mockups/dist/device-mockups.min.css";

export default {
  name: "LightboxCarousel",
  components: {
    CoolLightBox,
  },
  data() {
    return {
      images: [
        {
          title: "Dashboard",
          description: "Un coup d'oeil sur les 7 prochains jours",
          src: "screenshot-dashboard.png",
        },
        {
          title: "Votre page web publique",
          description:
            "Offrez à vos clients une page sur laquelle ils peuvent s'inscrire et réserver un cours",
          src: "public-organization.png",
        },
        {
          title: "Votre planning",
          description: "Point central pour gérer votre planning",
          src: "calendar.png",
        },
        {
          title: "Vos cours",
          description: "Définissez tous les paramètres de vos cours",
          src: "lessons.png",
        },
        {
          title: "Clients",
          description: "Accédez à une vue claire de tous vos clients",
          src: "clients.png",
        },
        {
          title: "Types d'adhésion",
          description:
            "Définissez vos abonnements, types de séance (gratuite ou unique), etc.",
          src: "screenshot-membership-types.png",
        },
      ],
      index: null,
    };
  },
  methods: {
    getLightBoxImage(i) {
      return {
        title: i.title,
        description: i.description,
        src: `/static/image/${i.src}`,
      };
    },
  },
};
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
/* .device-wrapper {
  width: 200px;
} */

/* ben182 
 * To control height you need little math:
 * Height 200px: 200 / 209.829619921363 * 100 = 
*/

.device-wrapper {
  width: 500px;
}
</style>