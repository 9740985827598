<template>
  <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
    <v-timeline-item
      color="orange lighten-2"
      icon="mdi-account-multiple"
      fill-dot
      class="mt-10"
    >
      <v-card color="orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          <h2 class="text-h6">Gestion administrative</h2>
        </v-card-title>

        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Accédez rapidement à toute la gestion quotidienne de votre activité.
            <ul class="feature">
              <li>Planning des cours</li>
              <li>Clients</li>
              <li>Abonnements</li>
              <li>Paiements</li>
              <li>Rapports d'activité</li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="deep-orange lighten-2" icon="mdi-web" fill-dot>
      <v-card color="deep-orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          <h2 class="text-h6">Visibilité sur internet</h2>
        </v-card-title>
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Via votre page web monplanning, offrez à vos anciens et nouveaux
            clients la liberté de :
            <ul class="feature">
              <li>Consulter le planning de vos cours</li>
              <li>S'inscrire</li>
              <li>Acheter un abonnement</li>
              <li>Réserver une séance</li>
            </ul>
            Et ce, entièrement en ligne.
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="orange lighten-2" icon="mdi-web" fill-dot>
      <v-card color="orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          <h2 class="text-h6">Diminution de votre charge de travail</h2>
        </v-card-title>
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Grâce à son système d'e-mails automatisés, vous et vos clients ne
            manqueront aucune communication importante.
            <ul class="feature">
              <li>Inscription d'un nouveau client</li>
              <li>Réservation ou désistement d'un cours</li>
              <li>Annulation d'un cours</li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="deep-orange lighten-2" icon="mdi-tag" fill-dot>
      <v-card color="deep-orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          <h2 class="text-h6">Recevoir des paiements</h2></v-card-title
        >
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Laissez facilement vos clients payer les cours et gardez le contrôle
            des réservations et des paiements.
            <ul class="feature">
              <li>Paiement en ligne par Visa, Mastercard, Bancontact, ..</li>
              <li>Paiement par virement bancaire</li>
              <li>Paiement en espèce</li>
              <li>Limitation de la période d'annulation d'un cours</li>
              <li>Limitation de la période de réservation d'un cours</li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="orange lighten-2" icon="mdi-calendar" fill-dot>
      <v-card color="orange lighten-2" dark>
        <v-card-title class="pa-2"
          ><h2 class="text-h6">Planning</h2></v-card-title
        >
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Affichez le planning de vos cours et ayez une vue claire du nombre
            de participants pour les prochains jours.

            <ul class="feature">
              <li>Vue mensuelle</li>
              <li>Vue hebdomadaire</li>
              <li>Vue quotidienne</li>
              <li>Tableau de bord complet</li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="deep-orange lighten-2" icon="mdi-account" fill-dot>
      <v-card color="deep-orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          <h2 class="text-h6">Professionnels</h2></v-card-title
        >
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Vous gérez un centre et/ou vous êtes plusieurs à donner des cours
            sous une même entité ? Ajoutez un nouveau professionnel à votre
            activité. Celui-ci pourra se connecter à monplanning, accéder à son
            planning et à ses cours.
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="orange lighten-2" icon="mdi-calendar" fill-dot>
      <v-card color="orange lighten-2" dark>
        <v-card-title class="pa-2"
          ><h2 class="text-h6">Multi-sites</h2></v-card-title
        >
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Si vous exercez votre activité dans plusieurs centres, lieux ou
            salles, monplanning vous laisse la possibilité de choisir à quel
            endroit vous dispensez le cours.
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>


<script>
export default {
  name: "Timeline",
  components: {},
  data() {
    return {};
  },
};
</script>