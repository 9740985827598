<template>
  <v-container fluid>
    <v-row>
      <v-container fluid class="gradient mt-2">
        <v-row justify="center" align="start">
          <v-col sm="8">
            <h1>
              <span class="text-h4 font-weight-medium">
                Vous et votre activité
              </span>
              <br />
              <span class="text-h4 primary--text font-weight-medium">
                méritez le meilleur !
              </span>
            </h1>
            <p class="mt-6">
              Pour les professionnels du secteur,
              <span class="font-weight-bold">l'achat d'un site web</span> ou
              d'un système de gestion est souvent très coûteux. Avec
              monplanning, vous avez tout en main, de la
              <span class="font-weight-bold"
                >gestion de votre activité administrative</span
              >
              jusqu'à votre propre
              <span class="font-weight-bold">page web</span>, <span class="font-weight-bold primary--text">à partir de 10 € / mois !</span>
            </p>
            <p>
              Présentez votre activité, affichez votre planning et laissez vos
              clients s'inscrire à vos cours et ce,
              <span class="font-weight-bold">entièrement en ligne</span> ! Un
              moyen supplémentaire pour toucher une clientèle plus vaste, de
              plus en plus connectée et ainsi diminuer votre charge de travail.
            </p>
          </v-col>
          <!-- <v-col sm="2" class="shrink">
            <v-img
              src="@/assets/features/mind-map-features.jpg"
              class="ml-3 mr-3 rounded-lg"
              max-height="280"
              max-width="350"
              contain
            ></v-img>
          </v-col> -->
        </v-row>

        <v-row align="start" justify="center" class="mt-8">
          <v-col sm="5">
            <lightbox-carousel />
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-container class="mt-0 pt-0 mb-0 pb-0 ml-0 mr-0 pl-0 pr-0">
      <v-row justify="center">
        <v-col sm="6" md="8">
          <FeaturesTimeline />
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <FreeTrialRegistration />
      </v-row>
    </v-container>
  </v-container>
</template>


<script>
import FreeTrialRegistration from "@/components/Public/FreeTrialRegistration";
import LightboxCarousel from "@/components/Public/LightboxCarousel.vue";
import FeaturesTimeline from "@/components/Public/FeaturesTimeline.vue";

export default {
  components: {
    FreeTrialRegistration,
    LightboxCarousel,
    FeaturesTimeline,
  },
  data() {
    return {};
  },
};
</script>

